<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card
      no-body
    >
      <b-card-body>
        <div>
          <b-row>
            <b-col
              md="6"
              class="flex justify-content-start"
            />
            <b-col
              md="3"
            >
              <div class="d-flex justify-content-end">
                <b-button
                  variant="secondary"
                  block
                  @click="GetData()"
                >
                  คืนค่า
                </b-button>
              </div>
            </b-col>
            <b-col
              md="3"
            >
              <div class="d-flex justify-content-end">
                <!-- <b-form-select
                  v-model="search_type"
                  class="d-inline-block mr-1"
                  :options="search_type_option"
                />

                <b-form-input
                  v-model="search_val"
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                /> -->
                <b-button
                  variant="primary"
                  block
                  @click="Submit()"
                >
                  บันทึก
                </b-button>
              </div>
            </b-col>
          </b-row>
          <!-- <div class="row">

            <div class="col">

            </div>
            <div class="col">

            </div>
            <div class="col"></div>
          </div>
          <div class="third1" /> -->
        </div>
      </b-card-body>
      <b-table
        small
        striped
        hover
        responsive
        class="position-relative items-center"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>

        <template #cell(subhead)="data">
          <div
            v-if="data.item.Subhead.length > 0"
            class="text-nowrap"
          >
            <b-button
              size="sm"
              :variant="data.detailsShowing ? 'secondary' : 'warning'"
              @click="data.toggleDetails"
            >
              {{ data.detailsShowing ? 'ซ่อน' : 'เพิ่มเติม' }}
            </b-button>
          </div>
        </template>

        <template #cell(status)="data">
          <div
            class="text-nowrap"
          >
            <b-form-checkbox
              v-model="data.item.status"
              :checked="data.item.status"
              class="custom-control-info"
              name="check-button"
              switch
              value="1"
              unchecked-value="0"
            >
              <span class="switch-icon-left">
                เปิด
              </span>
              <span class="switch-icon-right">
                ปิด
              </span>
            </b-form-checkbox>
          </div>
        </template>

        <template #cell(u3)="data">
          <div
            v-if="data.item.Subhead.length === 0"
            class="text-nowrap"
          >
            <b-form-checkbox
              v-model="data.item.u3"
              :checked="data.item.u3"
              class="custom-control-danger"
              name="check-button"
              switch
              value="1"
              unchecked-value="0"
            >
              <span class="switch-icon-left">
                เปิด
              </span>
              <span class="switch-icon-right">
                ปิด
              </span>
            </b-form-checkbox>
          </div>
        </template>

        <template #cell(d3)="data">
          <div
            v-if="data.item.Subhead.length === 0"
            class="text-nowrap"
          >
            <b-form-checkbox
              v-model="data.item.d3"
              :checked="data.item.d3"
              class="custom-control-danger"
              name="check-button"
              switch
              value="1"
              unchecked-value="0"
            >
              <span class="switch-icon-left">
                เปิด
              </span>
              <span class="switch-icon-right">
                ปิด
              </span>
            </b-form-checkbox>
          </div>
        </template>

        <template #cell(b3)="data">
          <div
            v-if="data.item.Subhead.length === 0"
            class="text-nowrap"
          >
            <b-form-checkbox
              v-model="data.item.b3"
              :checked="data.item.b3"
              class="custom-control-danger"
              name="check-button"
              switch
              value="1"
              unchecked-value="0"
            >
              <span class="switch-icon-left">
                เปิด
              </span>
              <span class="switch-icon-right">
                ปิด
              </span>
            </b-form-checkbox>
          </div>
        </template>

        <template #cell(t3)="data">
          <div
            v-if="data.item.Subhead.length === 0"
            class="text-nowrap"
          >
            <b-form-checkbox
              v-model="data.item.t3"
              :checked="data.item.t3"
              class="custom-control-danger"
              name="check-button"
              switch
              value="1"
              unchecked-value="0"
            >
              <span class="switch-icon-left">
                เปิด
              </span>
              <span class="switch-icon-right">
                ปิด
              </span>
            </b-form-checkbox>
          </div>
        </template>

        <template #cell(u2)="data">
          <div
            v-if="data.item.Subhead.length === 0"
            class="text-nowrap"
          >
            <b-form-checkbox
              v-model="data.item.u2"
              :checked="data.item.u2"
              class="custom-control-warning"
              name="check-button"
              switch
              value="1"
              unchecked-value="0"
            >
              <span class="switch-icon-left">
                เปิด
              </span>
              <span class="switch-icon-right">
                ปิด
              </span>
            </b-form-checkbox>
          </div>
        </template>

        <template #cell(d2)="data">
          <div
            v-if="data.item.Subhead.length === 0"
            class="text-nowrap"
          >
            <b-form-checkbox
              v-model="data.item.d2"
              :checked="data.item.d2"
              class="custom-control-warning"
              name="check-button"
              switch
              value="1"
              unchecked-value="0"
            >
              <span class="switch-icon-left">
                เปิด
              </span>
              <span class="switch-icon-right">
                ปิด
              </span>
            </b-form-checkbox>
          </div>
        </template>

        <template #cell(t2)="data">
          <div
            v-if="data.item.Subhead.length === 0"
            class="text-nowrap"
          >
            <b-form-checkbox
              v-model="data.item.t2"
              :checked="data.item.t2"
              class="custom-control-warning"
              name="check-button"
              switch
              value="1"
              unchecked-value="0"
            >
              <span class="switch-icon-left">
                เปิด
              </span>
              <span class="switch-icon-right">
                ปิด
              </span>
            </b-form-checkbox>
          </div>
        </template>

        <template #cell(u1)="data">
          <div
            v-if="data.item.Subhead.length === 0"
            class="text-nowrap"
          >
            <b-form-checkbox
              v-model="data.item.u1"
              :checked="data.item.u1"
              class="custom-control-success"
              name="check-button"
              switch
              value="1"
              unchecked-value="0"
            >
              <span class="switch-icon-left">
                เปิด
              </span>
              <span class="switch-icon-right">
                ปิด
              </span>
            </b-form-checkbox>
          </div>
        </template>

        <template #cell(d1)="data">
          <div
            v-if="data.item.Subhead.length === 0"
            class="text-nowrap"
          >
            <b-form-checkbox
              v-model="data.item.d1"
              :checked="data.item.d1"
              class="custom-control-success"
              name="check-button"
              switch
              value="1"
              unchecked-value="0"
            >
              <span class="switch-icon-left">
                เปิด
              </span>
              <span class="switch-icon-right">
                ปิด
              </span>
            </b-form-checkbox>
          </div>
        </template>

        <template #row-details="row">
          <b-card>
            <b-table
              responsive="sm"
              :items="row.item.Subhead"
              :fields="SubFields"
            >
              <template #cell(index)="data">
                {{ perPage * (currentPage - 1) + (data.index + 1) }}
              </template>
              <template #cell(status)="data">
                <div class="text-nowrap">
                  <b-form-checkbox
                    v-model="data.item.status"
                    :checked="data.item.status"
                    class="custom-control-info"
                    name="check-button"
                    switch
                    value="1"
                    unchecked-value="0"
                  >
                    <span class="switch-icon-left">
                      เปิด
                    </span>
                    <span class="switch-icon-right">
                      ปิด
                    </span>
                  </b-form-checkbox>
                </div>
              </template>

              <template #cell(u3)="data">
                <div class="text-nowrap">
                  <b-form-checkbox
                    v-model="data.item.u3"
                    :checked="data.item.u3"
                    class="custom-control-danger"
                    name="check-button"
                    switch
                    value="1"
                    unchecked-value="0"
                  >
                    <span class="switch-icon-left">
                      เปิด
                    </span>
                    <span class="switch-icon-right">
                      ปิด
                    </span>
                  </b-form-checkbox>
                </div>
              </template>

              <template #cell(d3)="data">
                <div class="text-nowrap">
                  <b-form-checkbox
                    v-model="data.item.d3"
                    :checked="data.item.d3"
                    class="custom-control-danger"
                    name="check-button"
                    switch
                    value="1"
                    unchecked-value="0"
                  >
                    <span class="switch-icon-left">
                      เปิด
                    </span>
                    <span class="switch-icon-right">
                      ปิด
                    </span>
                  </b-form-checkbox>
                </div>
              </template>

              <template #cell(t3)="data">
                <div class="text-nowrap">
                  <b-form-checkbox
                    v-model="data.item.t3"
                    :checked="data.item.t3"
                    class="custom-control-danger"
                    name="check-button"
                    switch
                    value="1"
                    unchecked-value="0"
                  >
                    <span class="switch-icon-left">
                      เปิด
                    </span>
                    <span class="switch-icon-right">
                      ปิด
                    </span>
                  </b-form-checkbox>
                </div>
              </template>

              <template #cell(u2)="data">
                <div class="text-nowrap">
                  <b-form-checkbox
                    v-model="data.item.u2"
                    :checked="data.item.u2"
                    class="custom-control-warning"
                    name="check-button"
                    switch
                    value="1"
                    unchecked-value="0"
                  >
                    <span class="switch-icon-left">
                      เปิด
                    </span>
                    <span class="switch-icon-right">
                      ปิด
                    </span>
                  </b-form-checkbox>
                </div>
              </template>

              <template #cell(d2)="data">
                <div class="text-nowrap">
                  <b-form-checkbox
                    v-model="data.item.d2"
                    :checked="data.item.d2"
                    class="custom-control-warning"
                    name="check-button"
                    switch
                    value="1"
                    unchecked-value="0"
                  >
                    <span class="switch-icon-left">
                      เปิด
                    </span>
                    <span class="switch-icon-right">
                      ปิด
                    </span>
                  </b-form-checkbox>
                </div>
              </template>

              <template #cell(t2)="data">
                <div class="text-nowrap">
                  <b-form-checkbox
                    v-model="data.item.t2"
                    :checked="data.item.t2"
                    class="custom-control-warning"
                    name="check-button"
                    switch
                    value="1"
                    unchecked-value="0"
                  >
                    <span class="switch-icon-left">
                      เปิด
                    </span>
                    <span class="switch-icon-right">
                      ปิด
                    </span>
                  </b-form-checkbox>
                </div>
              </template>

              <template #cell(u1)="data">
                <div class="text-nowrap">
                  <b-form-checkbox
                    v-model="data.item.u1"
                    :checked="data.item.u1"
                    class="custom-control-success"
                    name="check-button"
                    switch
                    value="1"
                    unchecked-value="0"
                  >
                    <span class="switch-icon-left">
                      เปิด
                    </span>
                    <span class="switch-icon-right">
                      ปิด
                    </span>
                  </b-form-checkbox>
                </div>
              </template>

              <template #cell(d1)="data">
                <div class="text-nowrap">
                  <b-form-checkbox
                    v-model="data.item.d1"
                    :checked="data.item.d1"
                    class="custom-control-success"
                    name="check-button"
                    switch
                    value="1"
                    unchecked-value="0"
                  >
                    <span class="switch-icon-left">
                      เปิด
                    </span>
                    <span class="switch-icon-right">
                      ปิด
                    </span>
                  </b-form-checkbox>
                </div>
              </template>
            </b-table>
          </b-card>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <!-- <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
            @change="GetData()"
          />
        </b-form-group> -->

        <!-- pagination -->
        <!-- <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="GetData()"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div> -->
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  // BInputGroupPrepend,
  // BModal,
  BCard, BTable,
  // BAvatar,
  // BFormGroup, BFormSelect, BPagination,
  // BInputGroup,
  // BFormInput,
  BButton, BCardBody, VBToggle, BOverlay, BIconController, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    // vSelect,
    // BInputGroupPrepend,
    // BModal,
    BCard,
    BTable,
    // BAvatar,
    // BFormGroup,
    // BFormSelect,
    // BPagination,
    // BInputGroup,
    // BFormInput,
    // BInputGroupAppend,
    BButton,
    BCardBody,
    BOverlay,
    BIconController,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      search_val: null,
      search_type: 4,
      show: true,
      depositdata: [],
      perPage: 15,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      banklist: [],
      fields: [
        { key: 'index', label: 'no.' },
        { key: 'name', label: 'หวย' },
        { key: 'Subhead', label: 'ประเภทหวย' },
        { key: 'status', label: 'สถานะ' },
        { key: 'u3', label: 'หวย 3 ตัวบน' },
        { key: 'd3', label: 'หวย 3 ตัวหน้า' },
        { key: 'b3', label: 'หวย 3 ตัวท้าย' },
        { key: 't3', label: 'หวย 3 ตัวโต๊ด' },
        { key: 'u2', label: 'หวย 2 ตัวบน' },
        { key: 'd2', label: 'หวย 2 ตัวล่าง' },
        { key: 't2', label: 'หวย 2 ตัวโต๊ด(บน)' },
        { key: 'u1', label: 'วิ่งบน' },
        { key: 'd1', label: 'วิ่งล่าง' },
        // { key: 'actions', label: 'Actions' },
      ],
      SubFields: [
        { key: 'index', label: 'no.' },
        { key: 'name', label: 'หวย' },
        { key: 'status', label: 'สถานะ' },
        { key: 'u3', label: 'หวย 3 ตัวบน' },
        { key: 'd3', label: 'หวย 3 ตัวล่าง' },
        { key: 't3', label: 'หวย 3 ตัวโต๊ด' },
        { key: 'u2', label: 'หวย 2 ตัวบน' },
        { key: 'd2', label: 'หวย 2 ตัวล่าง' },
        { key: 't2', label: 'หวย 2 ตัวโต๊ด(บน)' },
        { key: 'u1', label: 'วิ่งบน' },
        { key: 'd1', label: 'วิ่งล่าง' },
      ],
      /* eslint-disable global-require */
      items: [],
      DefaultItem: [],
      search_type_option: [
        { value: 4, text: 'ยูสเซอร์เนม' },
        { value: 3, text: 'เบอร์โทร' },
        { value: 1, text: 'IP ADDRESS' },
        { value: 2, text: 'BROWSER ID' },
      ],
      addNewDataSidebar: false,
      Interval: null,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    this.totalRows = this.items.length
    this.GetData()
  },
  methods: {
    CheckDiff() {
      const DiffArr = []
      const DiffSubArr = []
      // eslint-disable-next-line
      for (const K in this.items) {
        // eslint-disable-next-line
        for (const I in this.items[K]) {
          if (I === 'Subhead') {
            // eslint-disable-next-line
            for (const KeySub in this.items[K][I]) {
              // eslint-disable-next-line
              for (const KeyInSub in this.items[K][I][KeySub]) {
                if (this.items[K][I][KeySub][KeyInSub] !== this.DefaultItem[K][I][KeySub][KeyInSub]) {
                  DiffSubArr.push(this.items[K][I][KeySub])
                  break
                }
              }
            }
          // eslint-disable-next-line eqeqeq
          } else if (this.items[K][I] != this.DefaultItem[K][I] && I !== '_showDetails') {
            DiffArr.push(this.items[K])
            break
          }
        }
      }
      return { DiffArr, DiffSubArr }
    },
    Submit() {
      this.show = true
      const Diff = this.CheckDiff()
      this.$http
        .put('https://staffapi.ma5lotto.com/api/lottotype/update', Diff)
        .then(() => {
          this.show = false
          this.GetData()
          this.Success()
        })
        .catch(error => console.log(error))
      console.log(Diff)
    },
    async GetData() {
      await this.$http
        .get('https://staffapi.ma5lotto.com/api/lottotype')
        .then(response => {
          this.show = false
          this.onFiltered(response.data)
        })
        .catch(error => console.log(error))
    },
    onFiltered(filteredItems) {
      this.items = filteredItems
      const St = JSON.stringify(filteredItems)
      this.DefaultItem = JSON.parse(St)
      this.totalRows = this.items.length
      // this.totalRows = filteredItems.total
      // this.currentPage = 1
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
background-color:$product-details-bg;
}
